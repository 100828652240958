import React from 'react';
import { styled, Container } from '@mui/material';
import { StaticImage } from 'gatsby-plugin-image';
import { InView } from 'react-intersection-observer';
import { motion, useAnimation } from "framer-motion"
import IconList from './IconList';
import { StyledTypographySubtitle, StyledTypographyTitle } from './StyledTypography';
import { StyledBoxContainer, StyledBoxItem } from './StyledBox';
import { fadeInVariant, upVariant } from './AnimationVariants';

const Advantages: React.FC = () => {
  const StyledDiv = styled('div')(({theme}) => ({
    margin: '16vh 0 8vh 0',
  }));

  const ContainerTitle = styled(StyledBoxContainer)(({theme}) => ({
    [theme.breakpoints.up("md")]: {
      justifyContent: 'center'
    },
    [theme.breakpoints.down("mobile")]: {
      padding: '0 8vw',
    }
  }));

  const ContainerItem = styled(StyledBoxContainer)(({theme}) => ({
    [theme.breakpoints.down("mobile")]: {
      padding: '8vh 8vw',
    }
  }));

  const controls = useAnimation();
  const controls2 = useAnimation();
  const controls3 = useAnimation();

  return (
    <StyledDiv>
      <Container maxWidth={'lg'} disableGutters>
        <ContainerTitle>
          <StyledTypographyTitle align="center" component={"h2"}>
            We are flexible. You can engage us in various ways.
          </StyledTypographyTitle>
        </ContainerTitle>
        <InView as="div" onChange={(inView) => { inView ? controls.start("visible") : '' }} triggerOnce={true} threshold={0.2}>
          <ContainerItem sx={{ flexDirection: 'row', alignItems: 'center', padding: "8vh 0 8vh 0" }}>
            <StyledBoxItem>
              <motion.div animate={controls} initial="hidden" variants={fadeInVariant}>
                <StaticImage alt="workers" src="../images/3.png"/>
              </motion.div>
            </StyledBoxItem>
            <StyledBoxItem>
              <motion.div animate={controls} initial="hidden" variants={upVariant}>
                <StyledTypographySubtitle gutterBottom component={"h3"}>
                  Develop custom apps for your business
                </StyledTypographySubtitle>
              </motion.div>
              <IconList labels={["We have worked on many projects through many lifecycles", "Good and friendly team with solid experience", "Our rate is very competitive", "Frank and open discussion cause we don’t overpromise", "We always deliver" ]}/>
            </StyledBoxItem>
          </ContainerItem>
        </InView>
        <InView as="div" onChange={(inView) => { inView ? controls2.start("visible") : '' }} triggerOnce={true} threshold={0.2}>
          <ContainerItem sx={{ flexDirection: 'row-reverse', alignItems: 'center', padding: "0 0 0 0", }}>
            <StyledBoxItem>
              <motion.div animate={controls2} initial="hidden" variants={fadeInVariant}>
                <StaticImage alt="workers" src="../images/4.png"/>
              </motion.div>
            </StyledBoxItem>
            <StyledBoxItem>
              <motion.div animate={controls2} initial="hidden" variants={upVariant}>
                <StyledTypographySubtitle gutterBottom component={"h3"}>
                  Embed us into your current team to work on specific modules or apps.
                </StyledTypographySubtitle>
              </motion.div>
              <IconList labels={["Short term team extension", "We can temporarily be part of your team to help ensure you deliver your project on time"]}/>
            </StyledBoxItem>
          </ContainerItem>
        </InView>
        <InView as="div" onChange={(inView) => { inView ? controls3.start("visible") : '' }} triggerOnce={true} threshold={0.2}>
          <ContainerItem sx={{ flexDirection: 'row', alignItems: 'center', padding: "8vh 0 8vh 0" }}>
            <StyledBoxItem>
              <motion.div animate={controls3} initial="hidden" variants={fadeInVariant}>
                <StaticImage alt="workers" src="../images/5.png"/>
              </motion.div>
            </StyledBoxItem>
            <StyledBoxItem>
              <motion.div animate={controls3} initial="hidden" variants={upVariant}>
                <StyledTypographySubtitle gutterBottom component={"h3"}>
                  Devops consultant
                </StyledTypographySubtitle>
              </motion.div>
              <IconList labels={["Scalability, vertical or horizontal", "App architecture that scale", "Cost reduction on your app backend"]}/>
            </StyledBoxItem>
          </ContainerItem>
        </InView>
      </Container>
    </StyledDiv>
  );
}

export default Advantages;
