import React from "react";
import './index.css';
import Layout from "../components/Layout";
import Hero from "../components/Hero";
import Advantages from "../components/Advantages";
import WhatOurClientSay from "../components/WhatOurClientSay";
import ContactForm from "../components/ContactForm";
import { SEO } from "../components/seo";

const IndexPage = () => {
  return (
    <Layout>
      <Hero />
      <Advantages />
      {/* <WhatOurClientSay /> */}
      <ContactForm />
    </Layout>
  );
};

export default IndexPage;

export const Head = () => (
  <SEO title="Garasilabs"/>
)