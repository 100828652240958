import React from "react";
import { styled, Container } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import { InView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";
import IconList from "../components/IconList";
import {
  StyledTypographyHeroContent,
  StyledTypographyHeroTitle,
  StyledTypographyTitle,
} from "./StyledTypography";
import { StyledBoxContainer, StyledBoxItem } from "./StyledBox";
import {
  fadeInVariant,
  leftVariant,
  rightVariant,
  upVariant,
} from "./AnimationVariants";

const items = [
  "We have worked on several startup projects. We know what it takes.",
  "Develop your product MVP",
  "Refactor your current app",
  "Improve on UI design and overall UX",
  "General consultation on product development",
];

const Hero: React.FC = () => {
  const StyledDiv = styled("div")(({ theme }) => ({
    background:
      "transparent linear-gradient(270deg, #FFFFFF 0%, #E8F8FF 100%) 0% 0% no-repeat padding-box",
  }));

  const BoxContainer = styled(StyledBoxContainer)(({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      paddingBottom: "16vh",
    },
  }));

  const controls = useAnimation();
  const controls2 = useAnimation();

  return (
    <StyledDiv>
      <Container maxWidth={"lg"} disableGutters>
        <InView
          as="div"
          onChange={(inView) => {
            inView ? controls.start("visible") : "";
          }}
          triggerOnce={true}
          threshold={0.4}
        >
          <StyledBoxContainer
            sx={{ flexDirection: "row-reverse", alignItems: "center" }}
          >
            <StyledBoxItem>
              <motion.div
                animate={controls}
                initial="hidden"
                variants={fadeInVariant}
              >
                <StaticImage alt="workers" src="../images/1.png" />
              </motion.div>
            </StyledBoxItem>
            <StyledBoxItem>
              <motion.div
                animate={controls}
                initial="hidden"
                variants={leftVariant}
              >
                <StyledTypographyHeroTitle
                  gutterBottom
                  variant="h3"
                  component={"h1"}
                >
                  Outsourcing web and mobile app development made easy.
                  Competent and reliable team at a very affordable price.
                </StyledTypographyHeroTitle>
              </motion.div>
              <motion.div
                animate={controls}
                initial="hidden"
                variants={upVariant}
              >
                <StyledTypographyHeroContent gutterBottom component={"h4"}>
                  Garasilabs is a software development company who understands
                  that software should help your business, an important tool to
                  transform your business to a new level.
                </StyledTypographyHeroContent>
              </motion.div>
            </StyledBoxItem>
          </StyledBoxContainer>
        </InView>
        <InView
          as="div"
          onChange={(inView) => {
            inView ? controls2.start("visible") : "";
          }}
          triggerOnce={true}
          threshold={0.2}
        >
          <BoxContainer
            sx={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <StyledBoxItem>
              <motion.div
                animate={controls2}
                initial="hidden"
                variants={fadeInVariant}
              >
                <StaticImage alt="workers" src="../images/2.png" />
              </motion.div>
            </StyledBoxItem>
            <StyledBoxItem>
              <motion.div
                animate={controls2}
                initial="hidden"
                variants={rightVariant}
              >
                <StyledTypographyTitle gutterBottom component={"h3"}>
                  Are you a startup founder with brilliant idea and looking for
                  reliable developers?
                </StyledTypographyTitle>
              </motion.div>
              <IconList labels={items} />
            </StyledBoxItem>
          </BoxContainer>
        </InView>
      </Container>
    </StyledDiv>
  );
};

export default Hero;
